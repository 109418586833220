import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/contactUs",
    name: "contactUs",
    meta: {
      title: "联系我们"
    },
    component: ()=> import("../views/contactUs/ContactUs.vue"),// 联系我们
  },
  {
    path: "/newsList",
    name: "newsList",
    meta: {
      title: "北斗资讯"
    },
    component: ()=> import("../views/news/NewsList.vue"),// 列表页
  },
  {
    path: "/newsDetails/:id",
    name: "newsDetails",
    component: () =>
      import( /* webpackChunkName: "newsDetails" */ "../views/news/NewsDetails.vue"),
  },
  {
    path:"*",
    name:"404",
    meta: {
      title: "404"
    },
    component:()=>import("../views/404.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title;
  }
  next();
})

export default router;
